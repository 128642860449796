import React from 'react';
import shortid from  "shortid";
import FadeUpOnScroll from '../../../components/FadeUp';

const AboutSection3 = (props) => {

    return (
        <section className="section-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-18">
                        <div className="title-block">
                            <FadeUpOnScroll>
                                <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                            </FadeUpOnScroll>
                            <FadeUpOnScroll>
                                <p className="section-description small">{props.mainDescription}</p>
                            </FadeUpOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
                <div className="team-block">
                    <div className="row">
                        {props.teamList?.map((object, i) => {
                            return (
                                <div className="col-sm-8" key={shortid.generate()}>
                                    <FadeUpOnScroll>
                                        <div className="item" >
                                            <div className="image-block">
                                                <img
                                                    src={object.image1x?.sourceUrl}
                                                    srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                    width={object.image1x?.width}
                                                    alt={object.image1x?.altText}
                                                />
                                            </div>
                                            <p className="extra-large team-name">{object.name}</p>
                                            <p className="team-designation">{object.designation}</p>
                                        </div>
                                    </FadeUpOnScroll>
                                </div>    
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection3